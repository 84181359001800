import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { grossSalesData, netSalesData } from './salesData';

const SalesForecastChart = ({ darkMode, newData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const parseCSV = (csv) => {
      if (!csv || typeof csv !== 'string') {
        console.error('Invalid CSV data:', csv);
        return [];
      }

      try {
        const lines = csv.split('\n');
        if (lines.length < 2) {
          console.error('CSV data does not contain enough lines');
          return [];
        }
        return lines.slice(1).map(line => {
          const [date, salesString] = line.split('\t');
          if (!date || !salesString) {
            console.error('Invalid line in CSV:', line);
            return null;
          }
          return {
            date: date.trim(),
            sales: parseFloat(salesString.replace(/,/g, ''))
          };
        }).filter(item => item !== null);
      } catch (error) {
        console.error('Error parsing CSV:', error);
        return [];
      }
    };

    // Determine which data to use
    let currentGrossData = grossSalesData;
    let currentNetData = netSalesData;

    if (newData) {
      if (typeof newData === 'object' && newData.grossSales && newData.netSales) {
        currentGrossData = newData.grossSales;
        currentNetData = newData.netSales;
      } else {
        console.error('Invalid newData format:', newData);
      }
    }

    try {
      const grossHistoricalData = parseCSV(currentGrossData);
      const netHistoricalData = parseCSV(currentNetData);

      console.log('Parsed Gross Data:', grossHistoricalData);
      console.log('Parsed Net Data:', netHistoricalData);

      if (grossHistoricalData.length === 0 || netHistoricalData.length === 0) {
        console.error('No valid data parsed');
        return;
      }

      const improvedForecast = (data, periods) => {
        if (!Array.isArray(data) || data.length === 0) {
          console.error('Invalid data for forecast');
          return [];
        }

        const sales = data.map(d => d.sales);
        const n = sales.length;

        // Calculate overall trend
        const xMean = (n - 1) / 2;
        const yMean = sales.reduce((a, b) => a + b, 0) / n;
        const slope = sales.reduce((a, b, i) => a + (i - xMean) * (b - yMean), 0) / 
                      sales.reduce((a, b, i) => a + Math.pow(i - xMean, 2), 0);
        const intercept = yMean - slope * xMean;

        // Calculate seasonal factors (assuming 52-week seasonality)
        const seasonalFactors = Array(52).fill(0);
        for (let i = 0; i < n; i++) {
          const weekNum = i % 52;
          const trend = slope * i + intercept;
          seasonalFactors[weekNum] += sales[i] / trend;
        }
        for (let i = 0; i < 52; i++) {
          seasonalFactors[i] /= Math.floor(n / 52);
        }

        // Generate forecast
        const lastDate = new Date(data[data.length - 1].date.split(' - ')[1]);
        return Array(periods).fill().map((_, i) => {
          const weekNum = (n + i) % 52;
          const trend = slope * (n + i) + intercept;
          const seasonal = seasonalFactors[weekNum];
          const forecastValue = trend * seasonal;
          const forecastDate = new Date(lastDate.getTime() + (i + 1) * 7 * 24 * 60 * 60 * 1000);
          const endDate = new Date(forecastDate.getTime() + 6 * 24 * 60 * 60 * 1000);
          return {
            date: `${forecastDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} - ${endDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`,
            forecast: Math.max(0, forecastValue) // Ensure non-negative forecast
          };
        });
      };

      const grossForecast = improvedForecast(grossHistoricalData, 52);
      const netForecast = improvedForecast(netHistoricalData, 52);

      const dataLength = Math.min(grossHistoricalData.length, netHistoricalData.length);

      const combinedData = Array(dataLength).fill().map((_, index) => ({
        date: grossHistoricalData[index].date,
        grossSales: grossHistoricalData[index].sales,
        netSales: netHistoricalData[index].sales,
        grossForecast: null,
        netForecast: null
      }));

      const forecastData = grossForecast.map((item, index) => ({
        date: item.date,
        grossSales: null,
        netSales: null,
        grossForecast: item.forecast,
        netForecast: netForecast[index].forecast
      }));

      setData([...combinedData, ...forecastData]);
    } catch (error) {
      console.error('Error processing data:', error);
    }
  }, [newData]);

  const formatXAxis = (tickItem) => {
    if (!tickItem) return '';
    try {
      const date = new Date(tickItem.split(' - ')[0]);
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
    } catch (error) {
      console.error('Error formatting X axis:', error);
      return '';
    }
  };

  const formatYAxis = (value) => {
    if (!value && value !== 0) return '';
    return `£${(value / 1000).toFixed(0)}k`;
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ 
          backgroundColor: darkMode ? '#4a5568' : 'white', 
          padding: '10px', 
          border: '1px solid #ccc', 
          color: darkMode ? 'white' : 'black',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <p style={{ margin: '0 0 5px 0', fontWeight: 'bold' }}>{label}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ 
              color: entry.color,
              margin: '3px 0',
              fontSize: '0.9em'
            }}>
              {entry.name}: £{(entry.value || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ width: '100%', height: '600px', padding: '1rem' }}>
      <h2 style={{ 
        fontSize: '1.25rem', 
        fontWeight: 'bold', 
        marginBottom: '1rem', 
        color: darkMode ? 'white' : 'black' 
      }}>
        Weekly Sales and Forecast (Gross and Net)
      </h2>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" stroke={darkMode ? '#4a5568' : '#ccc'} />
          <XAxis 
            dataKey="date" 
            angle={-45} 
            textAnchor="end" 
            height={80} 
            interval={13}
            tickFormatter={formatXAxis}
            stroke={darkMode ? 'white' : 'black'}
          />
          <YAxis 
            tickFormatter={formatYAxis}
            stroke={darkMode ? 'white' : 'black'}
          />
          <Tooltip content={customTooltip} />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="grossSales" 
            stroke="#8884d8" 
            name="Gross Sales" 
            dot={false}
            strokeWidth={2}
          />
          <Line 
            type="monotone" 
            dataKey="netSales" 
            stroke="#82ca9d" 
            name="Net Sales" 
            dot={false}
            strokeWidth={2}
          />
          <Line 
            type="monotone" 
            dataKey="grossForecast" 
            stroke="#8884d8" 
            strokeDasharray="5 5" 
            name="Gross Forecast" 
            dot={false}
          />
          <Line 
            type="monotone" 
            dataKey="netForecast" 
            stroke="#82ca9d" 
            strokeDasharray="5 5" 
            name="Net Forecast" 
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesForecastChart;