import React, { useState } from 'react';

const CSVUploadModal = ({ isOpen, onClose, onDataProcessed, darkMode }) => {
  const [grossFile, setGrossFile] = useState(null);
  const [netFile, setNetFile] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  if (!isOpen) return null;

  const parseCSVFormat = (content, fileType) => {
    try {
      // Remove any BOM characters and trim
      content = content.replace(/^\ufeff/, '').trim();
      
      // Split into lines
      const lines = content.split(/[\r\n]+/).filter(line => line.trim());
      
      if (lines.length < 2) {
        throw new Error(`${fileType} file must contain at least headers and one data row`);
      }

      const processedLines = ['Created At\tSum of Total Invoiced (£)'];

      // Skip header line
      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].trim();
        if (!line) continue;

        // Parse CSV line properly
        const matches = line.match(/"([^"]+)"/g);
        if (!matches || matches.length !== 2) {
          throw new Error(`Invalid line format at line ${i + 1} in ${fileType} file`);
        }

        const date = matches[0].replace(/"/g, '').trim();
        const value = matches[1].replace(/"/g, '').trim();

        // Validate date format
        if (!/[A-Za-z]+ \d{1,2}, \d{4} - [A-Za-z]+ \d{1,2}, \d{4}/.test(date)) {
          throw new Error(`Invalid date format at line ${i + 1} in ${fileType} file. Expected format: "Month DD, YYYY - Month DD, YYYY"`);
        }

        // Clean and validate the numeric value
        const cleanValue = value.replace(/,/g, '');
        if (!/^\d+(\.\d{0,2})?$/.test(cleanValue)) {
          throw new Error(`Invalid number format at line ${i + 1} in ${fileType} file. Found: "${value}"`);
        }

        // Format the value with proper comma separators
        const formattedValue = parseFloat(cleanValue).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        processedLines.push(`${date}\t${formattedValue}`);
      }

      return processedLines.join('\n');
    } catch (err) {
      console.error(`CSV Processing Error (${fileType}):`, err);
      throw err;
    }
  };

  const handleFileChange = (event, fileType) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!selectedFile.name.endsWith('.csv')) {
        setError(`Please upload a CSV file for ${fileType}`);
        return;
      }
      if (fileType === 'gross') {
        setGrossFile(selectedFile);
      } else {
        setNetFile(selectedFile);
      }
      setError(null);
    }
  };

  const processFiles = async () => {
    if (!grossFile || !netFile) {
      setError('Please select both Gross Revenue and Net Sales files');
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      const grossContent = await grossFile.text();
      const netContent = await netFile.text();

      const processedGrossContent = parseCSVFormat(grossContent, 'Gross Revenue');
      const processedNetContent = parseCSVFormat(netContent, 'Net Sales');

      onDataProcessed({
        grossSales: processedGrossContent,
        netSales: processedNetContent
      });
      
      onClose();
      setGrossFile(null);
      setNetFile(null);
    } catch (err) {
      setError(err.message);
      console.error('File processing error:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  const FileInput = ({ label, onChange, file, fileType }) => (
    <div style={{ marginBottom: '15px' }}>
      <label style={{ 
        display: 'block', 
        marginBottom: '5px',
        color: darkMode ? '#e2e8f0' : '#4a5568',
        fontSize: '0.9rem'
      }}>
        {label}
      </label>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: '10px'
      }}>
        <div style={{
          position: 'relative',
          flex: 1,
        }}>
          <input
            type="file"
            accept=".csv,.txt"
            onChange={(e) => onChange(e, fileType)}
            style={{
              color: darkMode ? 'white' : '#333',
              backgroundColor: darkMode ? '#2d3748' : 'white',
              padding: '8px',
              borderRadius: '4px',
              border: darkMode ? '1px solid #4a5568' : '1px solid #e2e8f0',
              width: '100%',
              cursor: 'pointer'
            }}
          />
          {file && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '8px',
              transform: 'translateY(-50%)',
              color: darkMode ? '#e2e8f0' : '#4a5568',
              pointerEvents: 'none',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              right: '8px'
            }}>
              {file.name}
            </div>
          )}
        </div>
        {file && (
          <span style={{ 
            color: darkMode ? '#68D391' : '#38A169',
            fontSize: '0.9rem',
            flexShrink: 0
          }}>✓</span>
        )}
      </div>
    </div>
  );

  return (
    <div className="modal-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div className="modal-content" style={{
        backgroundColor: darkMode ? '#1a202c' : 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        width: '90%',
        maxHeight: '90vh',
        overflow: 'auto',
        position: 'relative',
        color: darkMode ? 'white' : '#333',
        border: darkMode ? '1px solid #2d3748' : 'none',
      }}>
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            border: 'none',
            background: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            color: darkMode ? 'white' : '#333',
          }}
        >
          ×
        </button>

        <h2 style={{ 
          marginBottom: '20px', 
          color: darkMode ? 'white' : '#333'
        }}>
          Upload Sales Data
        </h2>

        <FileInput 
          label="Gross Revenue File" 
          onChange={handleFileChange} 
          file={grossFile}
          fileType="gross"
        />

        <FileInput 
          label="Net Sales File" 
          onChange={handleFileChange} 
          file={netFile}
          fileType="net"
        />

        {error && (
          <div style={{
            padding: '10px',
            backgroundColor: darkMode ? '#742a2a' : '#fee2e2',
            border: `1px solid ${darkMode ? '#9b2c2c' : '#ef4444'}`,
            borderRadius: '4px',
            color: darkMode ? '#fbd5d5' : '#dc2626',
            marginBottom: '20px'
          }}>
            {error}
          </div>
        )}

        <div style={{
          backgroundColor: darkMode ? '#2d3748' : '#f3f4f6',
          padding: '15px',
          borderRadius: '4px',
          marginBottom: '20px',
          border: darkMode ? '1px solid #4a5568' : 'none',
        }}>
          <p style={{ 
            fontWeight: 'bold', 
            marginBottom: '8px',
            color: darkMode ? 'white' : '#333'
          }}>
            File Format Requirements:
          </p>
          <ul style={{ 
            paddingLeft: '20px',
            color: darkMode ? '#e2e8f0' : '#4a5568'
          }}>
            <li>Two separate CSV files required (Gross Revenue and Net Sales)</li>
            <li>Files must have headers: "Created At" and "Sum of Total Invoiced"</li>
            <li>Each row should contain a date range and value in quotes</li>
            <li>Date format: "Month DD, YYYY - Month DD, YYYY"</li>
            <li>Values should be numeric with optional commas and decimals (e.g., "169,866.21")</li>
          </ul>
        </div>

        <button
          onClick={processFiles}
          disabled={!grossFile || !netFile || isProcessing}
          style={{
            backgroundColor: !grossFile || !netFile || isProcessing 
              ? (darkMode ? '#4a5568' : '#9ca3af')
              : (darkMode ? '#3b82f6' : '#2563eb'),
            color: 'white',
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            cursor: (!grossFile || !netFile || isProcessing) ? 'not-allowed' : 'pointer',
            transition: 'background-color 0.2s',
            width: '100%'
          }}
        >
          {isProcessing ? 'Processing...' : 'Generate Forecast'}
        </button>
      </div>
    </div>
  );
};

export default CSVUploadModal;