import React, { useState } from 'react';
import SalesForecastChart from './SalesForecastChart';
import CSVUploadModal from './CSVUploadModal';

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [salesData, setSalesData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProcessedData = (csvContent) => {
    setSalesData(csvContent);
  };

  return (
    <div className={`App ${darkMode ? 'dark' : ''}`} style={{
      backgroundColor: darkMode ? '#1a202c' : 'white',
      color: darkMode ? 'white' : 'black',
      minHeight: '100vh',
      padding: '20px'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1>Sales Forecast Dashboard</h1>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            onClick={() => setIsModalOpen(true)}
            style={{
              padding: '10px',
              backgroundColor: darkMode ? '#4a5568' : '#e2e8f0',
              color: darkMode ? 'white' : 'black',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '5px'
            }}
          >
            <svg 
              width="16" 
              height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="17 8 12 3 7 8" />
              <line x1="12" y1="3" x2="12" y2="15" />
            </svg>
            Upload CSV
          </button>
          <button
            onClick={() => setDarkMode(!darkMode)}
            style={{
              padding: '10px',
              backgroundColor: darkMode ? '#4a5568' : '#e2e8f0',
              color: darkMode ? 'white' : 'black',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Toggle {darkMode ? 'Light' : 'Dark'} Mode
          </button>
        </div>
      </div>
      
      <CSVUploadModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDataProcessed={handleProcessedData}
      />
      
      <SalesForecastChart darkMode={darkMode} newData={salesData} />
    </div>
  );
}

export default App;