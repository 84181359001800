// salesData.js

export const grossSalesData = `Created At,Sum of Total Invoiced (£)
October 31, 2022 - November 6, 2022	169,866.21
November 7, 2022 - November 13, 2022	161,144.88
November 14, 2022 - November 20, 2022	179,170.57
November 21, 2022 - November 27, 2022	189,285.79
November 28, 2022 - December 4, 2022	167,473.64
December 5, 2022 - December 11, 2022	162,667.11
December 12, 2022 - December 18, 2022	142,718.64
December 19, 2022 - December 25, 2022	60,744.45
December 26, 2022 - January 1, 2023	64,728.50
January 2, 2023 - January 8, 2023	131,028.26
January 9, 2023 - January 15, 2023	147,672.29
January 16, 2023 - January 22, 2023	148,307.07
January 23, 2023 - January 29, 2023	145,995.85
January 30, 2023 - February 5, 2023	144,880.14
February 6, 2023 - February 12, 2023	136,559.76
February 13, 2023 - February 19, 2023	128,275.02
February 20, 2023 - February 26, 2023	156,376.03
February 27, 2023 - March 5, 2023	143,345.69
March 6, 2023 - March 12, 2023	136,821.62
March 13, 2023 - March 19, 2023	140,785.28
March 20, 2023 - March 26, 2023	142,441.90
March 27, 2023 - April 2, 2023	158,529.22
April 3, 2023 - April 9, 2023	132,332.30
April 10, 2023 - April 16, 2023	145,214.05
April 17, 2023 - April 23, 2023	141,240.01
April 24, 2023 - April 30, 2023	135,797.77
May 1, 2023 - May 7, 2023	138,925.65
May 8, 2023 - May 14, 2023	139,992.12
May 15, 2023 - May 21, 2023	149,759.01
May 22, 2023 - May 28, 2023	138,565.37
May 29, 2023 - June 4, 2023	145,285.86
June 5, 2023 - June 11, 2023	143,225.82
June 12, 2023 - June 18, 2023	152,499.97
June 19, 2023 - June 25, 2023	136,155.97
June 26, 2023 - July 2, 2023	142,292.25
July 3, 2023 - July 9, 2023	152,345.12
July 10, 2023 - July 16, 2023	149,647.09
July 17, 2023 - July 23, 2023	138,492.67
July 24, 2023 - July 30, 2023	138,420.91
July 31, 2023 - August 6, 2023	150,776.80
August 7, 2023 - August 13, 2023	150,457.23
August 14, 2023 - August 20, 2023	155,431.21
August 21, 2023 - August 27, 2023	149,205.42
August 28, 2023 - September 3, 2023	143,816.09
September 4, 2023 - September 10, 2023	139,465.42
September 11, 2023 - September 17, 2023	155,325.09
September 18, 2023 - September 24, 2023	150,139.90
September 25, 2023 - October 1, 2023	137,168.05
October 2, 2023 - October 8, 2023	145,023.55
October 9, 2023 - October 15, 2023	146,050.83
October 16, 2023 - October 22, 2023	181,010.44
October 23, 2023 - October 29, 2023	191,227.08
October 30, 2023 - November 5, 2023	194,417.81
November 6, 2023 - November 12, 2023	210,046.76
November 13, 2023 - November 19, 2023	201,151.19
November 20, 2023 - November 26, 2023	186,426.80
November 27, 2023 - December 3, 2023	198,679.82
December 4, 2023 - December 10, 2023	167,329.44
December 11, 2023 - December 17, 2023	142,002.93
December 18, 2023 - December 24, 2023	80,571.36
December 25, 2023 - December 31, 2023	72,337.89
January 1, 2024 - January 7, 2024	163,972.79
January 8, 2024 - January 14, 2024	176,321.29
January 15, 2024 - January 21, 2024	193,107.90
January 22, 2024 - January 28, 2024	200,599.61
January 29, 2024 - February 4, 2024	194,681.25
February 5, 2024 - February 11, 2024	208,308.60
February 12, 2024 - February 18, 2024	188,245.49
February 19, 2024 - February 25, 2024	195,738.04
February 26, 2024 - March 3, 2024	183,131.43
March 4, 2024 - March 10, 2024	204,029.94
March 11, 2024 - March 17, 2024	214,054.96
March 18, 2024 - March 24, 2024	225,253.97
March 25, 2024 - March 31, 2024	215,646.50
April 1, 2024 - April 7, 2024	240,279.51
April 8, 2024 - April 14, 2024	208,012.61
April 15, 2024 - April 21, 2024	183,357.30
April 22, 2024 - April 28, 2024	163,765.33
April 29, 2024 - May 5, 2024	205,522.41
May 6, 2024 - May 12, 2024	216,863.80
May 13, 2024 - May 19, 2024	198,507.48
May 20, 2024 - May 26, 2024	213,542.69
May 27, 2024 - June 2, 2024	220,304.17
June 3, 2024 - June 9, 2024	210,897.62
June 10, 2024 - June 16, 2024	204,047.89
June 17, 2024 - June 23, 2024	180,805.61
June 24, 2024 - June 30, 2024	206,120.01
July 1, 2024 - July 7, 2024	201,960.62
July 8, 2024 - July 14, 2024	195,124.11
July 15, 2024 - July 21, 2024	192,013.07
July 22, 2024 - July 28, 2024	210,179.28
July 29, 2024 - August 4, 2024	191,699.37
August 5, 2024 - August 11, 2024	200,864.87
August 12, 2024 - August 18, 2024	196,151.07
August 19, 2024 - August 25, 2024	219,317.36
August 26, 2024 - September 1, 2024	233,964.09
September 2, 2024 - September 8, 2024	248,455.18
September 9, 2024 - September 15, 2024	255,366.18
September 16, 2024 - September 22, 2024	257,007.23
September 23, 2024 - September 29, 2024	271,347.83
September 30, 2024 - October 6, 2024	272,041.08
October 7, 2024 - October 13, 2024	253,501.32
October 14, 2024 - October 20, 2024	259,155.09
October 21, 2024 - October 27, 2024	258,107.15`;

export const netSalesData = `Created At,Sum of Total Invoiced (£)
October 31, 2022 - November 6, 2022	164,207.26
November 7, 2022 - November 13, 2022	154,435.61
November 14, 2022 - November 20, 2022	172,472.28
November 21, 2022 - November 27, 2022	180,875.94
November 28, 2022 - December 4, 2022	160,177.47
December 5, 2022 - December 11, 2022	157,241.06
December 12, 2022 - December 18, 2022	133,995.30
December 19, 2022 - December 25, 2022	56,609.68
December 26, 2022 - January 1, 2023	62,158.11
January 2, 2023 - January 8, 2023	127,655.30
January 9, 2023 - January 15, 2023	145,095.12
January 16, 2023 - January 22, 2023	142,685.65
January 23, 2023 - January 29, 2023	142,335.19
January 30, 2023 - February 5, 2023	139,319.99
February 6, 2023 - February 12, 2023	132,474.08
February 13, 2023 - February 19, 2023	124,622.36
February 20, 2023 - February 26, 2023	150,569.39
February 27, 2023 - March 5, 2023	139,083.72
March 6, 2023 - March 12, 2023	130,331.80
March 13, 2023 - March 19, 2023	135,468.34
March 20, 2023 - March 26, 2023	137,875.57
March 27, 2023 - April 2, 2023	151,782.52
April 3, 2023 - April 9, 2023	126,036.38
April 10, 2023 - April 16, 2023	138,893.64
April 17, 2023 - April 23, 2023	134,869.33
April 24, 2023 - April 30, 2023	128,851.96
May 1, 2023 - May 7, 2023	131,724.82
May 8, 2023 - May 14, 2023	134,734.97
May 15, 2023 - May 21, 2023	145,009.45
May 22, 2023 - May 28, 2023	133,836.54
May 29, 2023 - June 4, 2023	134,294.77
June 5, 2023 - June 11, 2023	127,472.03
June 12, 2023 - June 18, 2023	138,326.31
June 19, 2023 - June 25, 2023	127,361.19
June 26, 2023 - July 2, 2023	130,307.94
July 3, 2023 - July 9, 2023	141,585.18
July 10, 2023 - July 16, 2023	144,538.68
July 17, 2023 - July 23, 2023	133,078.06
July 24, 2023 - July 30, 2023	134,476.74
July 31, 2023 - August 6, 2023	144,324.07
August 7, 2023 - August 13, 2023	139,657.12
August 14, 2023 - August 20, 2023	150,054.09
August 21, 2023 - August 27, 2023	143,095.21
August 28, 2023 - September 3, 2023	135,992.91
September 4, 2023 - September 10, 2023	134,150.95
September 11, 2023 - September 17, 2023	148,602.52
September 18, 2023 - September 24, 2023	142,082.29
September 25, 2023 - October 1, 2023	132,201.27
October 2, 2023 - October 8, 2023	137,826.06
October 9, 2023 - October 15, 2023	140,896.92
October 16, 2023 - October 22, 2023	174,541.60
October 23, 2023 - October 29, 2023	183,107.88
October 30, 2023 - November 5, 2023	185,664.80
November 6, 2023 - November 12, 2023	200,375.04
November 13, 2023 - November 19, 2023	191,470.42
November 20, 2023 - November 26, 2023	176,817.37
November 27, 2023 - December 3, 2023	186,325.23
December 4, 2023 - December 10, 2023	162,036.28
December 11, 2023 - December 17, 2023	135,719.01
December 18, 2023 - December 24, 2023	77,132.15
December 25, 2023 - December 31, 2023	69,736.88
January 1, 2024 - January 7, 2024	155,129.48
January 8, 2024 - January 14, 2024	169,020.94
January 15, 2024 - January 21, 2024	178,818.68
January 22, 2024 - January 28, 2024	192,177.94
January 29, 2024 - February 4, 2024	183,571.65
February 5, 2024 - February 11, 2024	201,348.29
February 12, 2024 - February 18, 2024	181,375
February 19, 2024 - February 25, 2024	184,322.01
February 26, 2024 - March 3, 2024	172,766.61
March 4, 2024 - March 10, 2024	192,363.97
March 11, 2024 - March 17, 2024	200,799.46
March 18, 2024 - March 24, 2024	211,173.39
March 25, 2024 - March 31, 2024	199,068.58
April 1, 2024 - April 7, 2024	218,664.47
April 8, 2024 - April 14, 2024	192,176.71
April 15, 2024 - April 21, 2024	167,967.34
April 22, 2024 - April 28, 2024	148,235.42
April 29, 2024 - May 5, 2024	191,703.51
May 6, 2024 - May 12, 2024	203,497.79
May 13, 2024 - May 19, 2024	182,224.25
May 20, 2024 - May 26, 2024	196,453.79
May 27, 2024 - June 2, 2024	199,406.85
June 3, 2024 - June 9, 2024	194,146.41
June 10, 2024 - June 16, 2024	188,341.38
June 17, 2024 - June 23, 2024	163,493.57
June 24, 2024 - June 30, 2024	189,552.80
July 1, 2024 - July 7, 2024	182,921.58
July 8, 2024 - July 14, 2024	173,633.60
July 15, 2024 - July 21, 2024	172,669.91
July 22, 2024 - July 28, 2024	185,642.59
July 29, 2024 - August 4, 2024	174,104.88
August 5, 2024 - August 11, 2024	180,571.55
August 12, 2024 - August 18, 2024	180,764.83
August 19, 2024 - August 25, 2024	202,179.19
August 26, 2024 - September 1, 2024	212,784.26
September 2, 2024 - September 8, 2024	237,697.16
September 9, 2024 - September 15, 2024	244,472.94
September 16, 2024 - September 22, 2024	240,395.70
September 23, 2024 - September 29, 2024	254,747.09
September 30, 2024 - October 6, 2024	258,910.75
October 7, 2024 - October 13, 2024	237,447.99
October 14, 2024 - October 20, 2024	244,808.60
October 21, 2024 - October 27, 2024	245,995.55`;